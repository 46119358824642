html, body {
  margin:0;
  border:0;
  height: 100%;
}
.page-content {
  height: 100%;
  width:100%;
}
/* LANDING PAGE */

@keyframes Transistion { 
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.header-color {
  background: linear-gradient(270deg, #22c1c3, #fdbb2d);
  background-size: 600% 600%;
  animation: Transistion 6s ease infinite;
}

.header-color :hover {
  color: #178788;
}

.landing-page {
  background-image: url('./components/img/clouds.JPG');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - 207px);
}

.landing-grid {
  margin: auto;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  /*background: linear-gradient(270deg, #22c1c3, #fdbb2d);
  background-size: 600% 600%;

  animation: Transistion 6s ease infinite;*/
}

.proj-img {
  white-space: nowrap;
  text-align: center; margin: 3em 0;
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.proj-img img {
  width:100%;
  vertical-align: middle;
}

.banner-text {
  background-color: black;
  opacity: .8;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  display:table;
  padding: 1rem;
}
.v-align {
  padding: 1rem;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.banner-text h1 {
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 0.2rem dotted white;
  width: 60%;
  margin: auto;
}

.banner-text p {
  color: white; 
  font-size: 1.5rem;
}

.banner-text i {
  color: white;
  font-size: 1em;
  padding-left: 1vw;
  padding-right: 1vw;
}

/* PROJECTS PAGE */
.content {
  padding-top: 3em;
}
.category-tabs {
  color: black;
  background-image: url('./components/img/clouds.JPG');
  background-size: cover;
  width: 100%;
  min-height: calc(100% - 207px);
  left: 0;
}
.category-tabs h1 {
  color: black;
  margin: auto;
  position: flex;
}

.project-grid {
  display: flex;
}

.project-grid h1 {
  font-size: 2em;
}

/* CONTACT PAGE */
.contact-body {
  margin: auto;
  background: grey;
  position: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}
.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  border: 2px solid teal;
  margin-bottom: 20px;
}

/* Resume Page */
.res-right-col {
  background: #27221F;
  opacity: 0.8;
  color: white;
  padding: 2em;
  width:80%;
  height:100%;
  margin:0 auto;
}
.res-hiframe {
  position:relative;
}
.res-hiframe .ratio {
  display:block;
  width:100%;
  height:auto;
}

.res-hiframe iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

/* Projects Inner Pages Style */

.proj{
  margin: auto;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  background-image: url('./components/img/clouds.JPG');
  background-size: cover;
}

.proj h1 {
  color: teal;
  font-size: 3em;
}
.proj h3 {
  font-size: 2em;
  color: teal;
}

.proj h5 {
  color: teal;
}

.proj p {
  font-size: 1.5em;
}
.proj figure {
  float: 'right';
  margin: '0px 0px 15px 15px';
  font-style:'italic';
  text-align:'center';
  color:'#447374';
}

/*Art Portfolio*/
.left-col {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-top:10%;
  background: rgba(114, 186, 94, 0.3);
}
.ava {
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
}

/*Footer*/
.footer {
  justify-content: space-between;
  position: relative; 
  bottom:0; 
  width:100%;
  height:207px;
}
.footer p {
  font-size: 1.3em;
  margin-top: 1em;
  color: white;
}
.footer h4 {
  color:white;
}
.footer-social-links i{
  font-size: 2em;
  margin-left: 15px;
  margin-right: 15px;
  color: white
}

